<template>
  <div>
    <!-- <div>
      <p>{{ this.rowData }}</p>
    </div> -->
    <div class="row justify-content-center justify-content-around">
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">รายการทรัพย์สิน</p>
          </div>
          <hr />
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1 @click="genauto">เพิ่มประเภททรัพย์สิน</b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">Search</label>
                <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export/Import <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                    <b-dropdown-item v-b-modal.modal-Import>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Import Excel
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Export Excel
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <b-modal id="modal-Import" title="Import Excel" ok-title="ตกลง" cancel-title="ยกเลิก" @ok="Import_Excel" no-close-on-backdrop>
            <b-card-text>
              <h5>นำเข้าไฟล์ Excel</h5>
              <b-form-file type="file" @change="onFileChange" placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'delete'">
                  <b-button variant="outline-danger" @click="deldata(props.row)"> <feather-icon icon="Trash2Icon" size="16" class="mx-1" /></b-button>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'edit'">
                  <b-button variant="outline-info" v-b-modal="`modal-edit${props.index}`" @click="editdata(props.row)">
                    <feather-icon icon="Edit3Icon" size="16" class="mx-1" />
                  </b-button>
                  <b-modal
                    :id="`modal-edit${props.index}`"
                    cancel-variant="outline-secondary"
                    ok-title="บันทึก"
                    cancel-title="ยกเลิก"
                    title="แก้ไขประเภททรัพย์สิน"
                    size=""
                    @ok="edit_data(props.row)"
                    no-close-on-backdrop
                  >
                    <b-form>
                      <div>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ประเภททรัพย์สิน:">
                          <template v-slot:label> ประเภททรัพย์สิน <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="e_id" placeholder="ประเภททรัพย์สิน" disabled />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อทรัพย์สิน:">
                          <template v-slot:label> ชื่อทรัพย์สิน <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="e_name" placeholder="ชื่อทรัพย์สิน" />
                        </b-form-group>

                        <b-form-group label-cols="4" label-cols-lg="4" label=" อายุการใช้งาน (ปี):">
                          <template v-slot:label>
                            อายุการใช้งาน (ปี)
                            <span class="text-danger"> *</span>
                          </template>
                          <b-form-input id="basicInput" v-model="e_lifetime" placeholder=" อายุการใช้งาน" @input="chang" />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label=" อัตราค่าเสื่อมร้อยละ (ปี):">
                          <template v-slot:label>
                            อัตราค่าเสื่อมร้อยละ (ปี)
                            <span class="text-danger"> </span>
                          </template>
                          <b-form-input
                            id="basicInput"
                            v-model="e_depreciation_year"
                            placeholder=" อัตราค่าเสื่อมร้อยละ"
                            @input="chang"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label=" อัตราค่าเสื่อมต่อวัน (วัน):">
                          <template v-slot:label>
                            อัตราค่าเสื่อมต่อวัน (วัน)
                            <span class="text-danger"> </span>
                          </template>
                          <b-form-input
                            id="basicInput"
                            v-model="e_depreciation_day"
                            placeholder=" อัตราค่าเสื่อมต่อวัน"
                            @input="chang"
                            disabled
                          />
                        </b-form-group>
                        <b-form-group label-cols="4" label-cols-lg="4" label="มูลค่าซาก :">
                          <template v-slot:label> มูลค่าซาก <span class="text-danger"> *</span> </template>
                          <b-form-input id="basicInput" v-model="e_amortization" placeholder="มูลค่าซาก" />
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value })"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value) => handlePageChange(value)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        title="ทรัพย์สิน"
        size="md"
        no-close-on-backdrop
        @ok="getdata"
      >
        <b-form>
          <div>
            <b-form-group label-cols="4" label-cols-lg="4" label="ประเภททรัพย์สิน:">
              <template v-slot:label> ประเภททรัพย์สิน <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="e_id" placeholder="ประเภททรัพย์สิน" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="ชื่อทรัพย์สิน:">
              <template v-slot:label> ชื่อทรัพย์สิน <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="e_name" placeholder="ชื่อทรัพย์สิน" />
            </b-form-group>

            <b-form-group label-cols="4" label-cols-lg="4" label=" อายุการใช้งาน (ปี):">
              <template v-slot:label> อายุการใช้งาน (ปี) <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="e_lifetime" placeholder=" อายุการใช้งาน" @input="chang" />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label=" อัตราค่าเสื่อมร้อยละ (ปี):">
              <template v-slot:label> อัตราค่าเสื่อมร้อยละ (ปี) <span class="text-danger"> </span> </template>
              <b-form-input id="basicInput" v-model="e_depreciation_year" placeholder=" อัตราค่าเสื่อมร้อยละ" @input="chang" disabled />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label=" อัตราค่าเสื่อมต่อวัน (วัน):">
              <template v-slot:label> อัตราค่าเสื่อมต่อวัน (วัน) <span class="text-danger"> </span> </template>
              <b-form-input id="basicInput" v-model="e_depreciation_day" placeholder=" อัตราค่าเสื่อมต่อวัน" @input="chang" disabled />
            </b-form-group>
            <b-form-group label-cols="4" label-cols-lg="4" label="มูลค่าซาก :">
              <template v-slot:label> มูลค่าซาก <span class="text-danger"> *</span> </template>
              <b-form-input id="basicInput" v-model="e_amortization" placeholder="มูลค่าซาก" />
            </b-form-group>
            <!-- <b-form-group label-cols="4" label-cols-lg="4" label=" ราคาซาก :">
              <template v-slot:label>
                ราคาซาก <span class="text-danger"> *</span>
              </template>
              <b-form-input
                id="basicInput"
                v-model="e_remain"
                placeholder=" ราคาซาก"
              />
            </b-form-group> -->
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import { BFormInput, BRow, BCol, BFormGroup } from 'bootstrap-vue';
import { BTable, BFormRadio, BFormCheckbox, BFormRadioGroup, BCardBody } from 'bootstrap-vue';
import vSelect from 'vue-select';
import { BButton } from 'bootstrap-vue';
import { BFormFile } from 'bootstrap-vue';
import { BFormSelect } from 'bootstrap-vue';
import { BFormDatepicker } from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import axios from 'axios';
import API from '@/views/connectDB/condb.js';
import { saveExcel } from '@progress/kendo-vue-excel-export';
import readXlsxFile from 'read-excel-file';
import _ from 'lodash';

export default {
  props: {
    rowData: Object,
  },
  components: {
    VueGoodTable,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BRow,
    BCol,
    BButton,
    BTable,
    BCardBody,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BTabs,
    BTab,
    readXlsxFile,
  },

  data() {
    return {
      show: false,
      e_id: '',
      e_name: '',
      e_lifetime: '',
      e_depreciation_year: '',
      e_depreciation_day: '',
      e_amortization: '',
      e_remain: '',

      pageLength: 10,
      total: null,

      dir: false,
      columns: [
        {
          label: 'กลุ่มทรัพย์สิน',
          field: 'e_gid',
        },
        {
          label: 'ประเภททรัพย์สิน',
          field: 'e_id',
        },
        {
          label: 'ชื่อทรัพย์สิน',
          field: 'e_name',
        },
        {
          label: 'อายุการใช้งาน',
          field: 'e_lifetime',
        },
        {
          label: 'อัตราค่าเสื่อมร้อยละ (ปี)',
          field: 'e_depreciation_year',
        },
        {
          label: 'อัตราค่าเสื่อมต่อวัน',
          field: 'e_depreciation_day',
        },
        {
          label: 'มูลค่าซาก',
          field: 'e_amortization',
        },
        {
          label: 'แก้ไข',
          field: 'edit',
        },
        {
          label: 'ลบ',
          field: 'delete',
        },
      ],
      rows: [],
      searchTerm: '',
    };
  },
  computed: {
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  async created() {
    console.log(this.rowData);
    _.isEmpty(this.rowData) ? this.$router.push({ path: '/equipment-group' }) : true;

    this.show = true;
    const { e_gid } = this.rowData;
    const { access_token } = await this.access_token();
    const url = `${API}equipmentNames?_page=1&_limit=100&_sort=-1&e_gid=${e_gid}`;
    const header = {
      headers: {
        Authorization: await access_token,
      },
    };
    const res = await axios.get(url, header);
    this.rows = res.data.message.data;
    this.total = res.data.message.total;
    this.show = false;
  },
  methods: {
    editdata(value){
      console.log(value)
      const {e_id,e_name,e_lifetime,e_depreciation_year,e_depreciation_day,e_amortization} = value
      this.e_id = e_id
      this.e_name = e_name
      this.e_lifetime = e_lifetime
      this.e_depreciation_year = e_depreciation_year
      this.e_depreciation_day = e_depreciation_day
      this.e_amortization = e_amortization
    },
    async deldata(value) {
      console.log(value);
      const { e_gid, e_id, e_name } = value;

      Swal.fire({
        title: 'คุณต้องการจะลบข้อมูลใช่หรือไม่',
        text: `ทรัพย์สิน กลุ่มทรัพย์สิน ${e_gid} ประเภททรัพย์สิน ${e_id} ชื่อทรัพย์สิน ${e_name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}equipmentNames/${e_gid}&${e_id}`;
          const head = {
            headers: {
              Authorization: await access_token,
            },
          };
          const res = await axios.delete(url, head);

          console.log(res);
          Swal.fire({
            icon: 'success',
            title: 'ลบข้อมูลสำเร็จ!',
            text: `รายการนี้ ถูกลบเเล้ว `,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
        this.handlePageChange();
      });
    },
    async Import_Excel() {
      const { access_token } = await this.access_token();
      const url = `${API}equipmentNames`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      // console.log(this.Excel);

      var data = [];
      for (let i in this.Excel) {
        data.push({
          e_gid: this.Excel[i][0],
          e_id: this.Excel[i][1],
          e_name: this.Excel[i][2],
          e_lifetime: this.Excel[i][3],
          e_depreciation_year: this.Excel[i][4],
          e_depreciation_day: this.Excel[i][5],
          e_amortization: this.Excel[i][6],
        });
      }
      data.forEach(async (element) => {
        const post_data = {
          e_gid: element.e_gid,
          e_id: element.e_id,
          e_name: element.e_name,
          e_lifetime: element.e_lifetime.toString(),
          e_depreciation_year: element.e_depreciation_year.toString(),
          e_depreciation_day: element.e_depreciation_day.toString(),
          e_amortization: element.e_amortization.toString(),
        };
        // console.log(post_data);
        const res = await axios.post(url, post_data, head);
        // console.log(res);
      });
      this.handlePageChange();
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        const xx = rows.filter((element, index) => {
          return index >= 2;
        });
        // console.log(xx);
        const num = {};
        const doubly = {};

        const uu = xx.filter((res, index) => {
          let empty = true;
          res.map((res2, index) => {
            if (res2 === null || res2 === 'null' || res2 === undefined || res2 === 'undefined' || res2 === '') {
              empty = false;
            }

            if (index === 1) {
              if (num[res2]) {
                num[res2]++;
                doubly[res2] = res;
              } else {
                num[res2] = 1;
              }
            }
          });
          return empty && res;
        });
        // console.log(uu);
        // console.log(doubly);
        if (!!!_.isEmpty(doubly)) {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: `ประเภททรัพย์สินซ้ำ ${Object.values(doubly)}`,
            showConfirmButton: true,
          });
          this.Excel = uu;
        } else {
          this.Excel = uu;
        }
      });
    },
    async genauto() {
      const { access_token } = await this.access_token();
      const url = `${API}genAutoEquipmentCode?e_gid=${this.rowData.e_gid}`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      this.e_id = res.data.message.data[0].gen;
      this.e_name = ''
      this.e_lifetime = ''
      this.e_depreciation_year = ''
      this.e_depreciation_day = ''
      this.e_amortization = ''
      // console.log(res.data.message);
    },
    async edit_data(value) {
      // console.log(value);
      const { access_token } = await this.access_token();
      const url = `${API}equipmentNames/${value.e_gid}&${value.e_id}`;
      const data = {
        e_name: this.e_name,
        e_lifetime:  this.e_lifetime.toString(),
        e_depreciation_year: this.e_depreciation_year.toString(),
        e_depreciation_day: this.e_depreciation_day.toString(),
        e_amortization: this.e_amortization.toString(),
        e_remain: this.e_remain.toString(),
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };

      const res = await axios.put(url, data, head);
      this.handlePageChange();
      // console.log(res);
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: 'รายการทรัพย์สิน',
        columns: [
          {
            title: 'รายการทรัพย์สิน',
            headerCellOptions: { textAlign: 'center' },
            children: [
              {
                title: 'กลุ่มทรัพย์สิน',
                field: 'e_gid',
              },
              {
                title: 'ประเภททรัพย์สิน',
                field: 'e_id',
              },
              {
                title: 'ชื่อทรัพย์สิน',
                field: 'e_name',
              },
              {
                title: 'อายุการใช้งาน',
                field: 'e_lifetime',
              },
              {
                title: 'อัตราค่าเสื่อมร้อยละ (ปี)',
                field: 'e_depreciation_year',
              },
              {
                title: 'อัตราค่าเสื่อมต่อวัน',
                field: 'e_depreciation_day',
              },
              {
                title: 'มูลค่าซาก',
                field: 'e_amortization',
              },
            ],
          },
        ],
      });
    },
    async chang() {
      this.e_depreciation_year = (100 / this.e_lifetime).toFixed(2);
      this.e_depreciation_day = (this.e_lifetime / 365).toFixed(4);

      // console.log("อัตราค่าเสื่อมร้อยละ (ปี) ", this.e_depreciation_year);
      // console.log("อัตราค่าเสื่อมต่อวัน (วัน) ", this.e_depreciation_day);
      // const xx = this.e_lifetime / this.e_depreciation_year;
      // this.e_depreciation_day = xx / 365;
    },
    async getdata() {
      // const year = Number(this.e_depreciation_year);
      // const day = Number(this.e_depreciation_day);
      // const sum = year / day;

      // console.log(sum);
      // return;
      this.e_id = this.e_id.replace(' ', '');
      const QC = this.rows.find(({ e_id }) => e_id === this.e_id);
      if (QC) {
        Swal.fire({
          position: 'top-center',
          icon: 'error',
          title: `ข้อมูลซ้ำ ${QC.e_id} - ${QC.e_name}`,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        const { e_gid } = this.rowData;
        const { access_token } = await this.access_token();
        const data = {
          e_gid: e_gid,
          e_id: this.e_id,
          e_name: this.e_name,
          e_lifetime: this.e_lifetime,
          e_depreciation_year: this.e_depreciation_year + '',
          e_depreciation_day: this.e_depreciation_day + '',
          e_amortization: this.e_amortization,
          e_remain: this.e_amortization,
        };
        axios
          .post(API + 'equipmentNames', data, {
            headers: {
              Authorization: access_token,
            },
          })
          .then((response) => {
            // console.log(response);
            if (response.data.status == 200) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: 'บันทึกข้อมูล',
                showConfirmButton: false,
                timer: 1500,
              });
              this.handlePageChange();
            } else {
              Swal.fire({
                position: 'center',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              position: 'center',
              icon: 'error',
              title: error.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            // console.log(error);
          });
      }
    },

    async handlePageChange(currentPage) {
      this.show = true;
      const { e_gid } = this.rowData;
      const { access_token } = await this.access_token();
      const url = `${API}equipmentNames?_page=${currentPage}&_limit=${this.pageLength}&_sort=-1&e_gid=${e_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);
      this.rows = res.data.message.data;
      this.total = res.data.message.total;
      this.show = false;
    },

    async access_token() {
      return (
        await axios.post(`${API}permit`, '', {
          headers: {
            Authorization: localStorage.getItem('storedData') && JSON.parse(localStorage.getItem('storedData')),
          },
        })
      ).data.message;
    },
  },
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
